<div class="topmenu">
  <div class="container">
    <button *ngIf="layoutService.isMobileLayout"
            (click)="toggleSideMenu()"
            class="sideMenuButton"
            mat-icon-button
            aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="row justify-content-center">
      <a 
         routerLink="over"><img class="navBarIcon"
             src="/assets/images/pts_logo.png" /></a>
      <div class="menu">
        <a class="menuLink"
           *ngFor="let item of layoutService.menuItems"
           [routerLink]="item.routerLink"
           [queryParams]="item.routerLink === 'app' ? {skipdialog: 'true'} : {}"
           routerLinkActive="active">
          {{item.name}}
        </a>
      </div>
    </div>
  </div>
</div>