import { Injectable, EventEmitter, Output } from '@angular/core';
import { CartoService } from './carto.service';
import { MapConfig } from './carto.interface';

@Injectable({
  providedIn: 'root'
})
export class BusinessCaseService {

  // Constants for the suitability (1st step)
  buildableAreaPercentage: number = 0.6; //Percentage bebouwbaar oppervlak, 60%
  public solarPanelPower: number = 200; // Solar panel power in Wp/m2
  customSolarPanelPower: number = 0;
  lowVoltageGridDistance: number; // meters to nearest low voltage grid
  customSunnyArea: number;
  customMidVoltageGridDistance: number;
  defaultLowVoltageGridDistance: number = 100;
  customLowVoltageGridDistance: number;
  lowVoltageGridCostsFixed: number = 4802;
  lowVoltageGridExtraLengthTariff: number = 52.4;
  monthlyLowVoltageGridCostsVariable: number = 10.78;
  showBusinessDetails: boolean = false;

  stage1GridDistanceOption = undefined;

  stage1GridConnections = [
    { value: 15, name: '3x25A (standaard huisaansluiting)' },
    { value: 55, name: '3x80A (kleinzakelijke aansluiting)' },
    { value: 160, name: '3x80A t/m 160 kVA (laagspanning)' },
    { value: 630, name: '160kVA t/m 630 kVA (middenspanning)' },
    { value: 1000, name: '630 kVA t/m 1.000 kVA (middenspanning)' },
    { value: 2000, name: '1.000 kVA t/m 2.000 kVA (middenspanning)' },
    { value: 999999, name: '> 2.000 kVA (hoogspanning)' }
  ]

  yesNoGridDistanceDropdown = {
    hintText: 'Heeft u deze netaansluiting?',
    items: [
      { value: 0, name: 'Nee' },
      { value: 1, name: 'Ja' }
    ]
  };
  public yesNoGridConnection: number = 0;

  // Constants for the investment part
  public legesFraction: number = 0.015;
  public carportOperationalYears: number = 25;
  public insuranceFractionYearly: number = 0.015;
  lowVoltageGridCostEstimation: number = 5000;
  gridCostsFixed: { threshold: number, tariff: number }[] = [
    { threshold: 100, tariff: 4313 }, // Threshold is in kWp, tariff is in euros 
    { threshold: 160, tariff: 4802 },
    { threshold: 630, tariff: 17651 },
    { threshold: 1000, tariff: 24014 },
    { threshold: 2000, tariff: 34740 },
    { threshold: 5000, tariff: 226725 },
    { threshold: 10000, tariff: 269250 },
    //Tariff for higher ranges is "maatwerk"
  ]

  gridCostsExtraLength: { threshold: number, tariff: number }[] = [
    { threshold: 100, tariff: 44.4 }, // Threshold is in kWp, tariff is in euros/m 
    { threshold: 160, tariff: 52.4 },
    { threshold: 630, tariff: 105 },
    { threshold: 1000, tariff: 105 },
    { threshold: 2000, tariff: 105 },
    { threshold: 5000, tariff: 105 },
    { threshold: 10000, tariff: 175.5 },
    //Tariff for higher ranges is "maatwerk"
  ]

  monthlyGridCostsVariable: { threshold: number, tariff: number }[] = [
    { threshold: 100, tariff: 10.78 }, // Threshold is in Wp, tariff is in euros /month
    { threshold: 160, tariff: 12.01 },
    { threshold: 630, tariff: 45.67 },
    { threshold: 1000, tariff: 45.67 },
    { threshold: 2000, tariff: 86.73 },
    { threshold: 5000, tariff: 567 },
    { threshold: 10000, tariff: 672.75 },
    //Tariff for higher ranges is "maatwerk"
  ]

  public solarCarportOptions = {
    hintText: 'Kies een type solar carport',
    items: [
      { name: 'Standaard carport', value: 0 },
      { name: 'Gepersonaliseerde carport', value: 1 },
      { name: 'Verhoogde carport', value: 2 },
      { name: 'Houten carport', value: 3 },
    ]
  }
  solarCarportType: number = 0;

  public funderingOptions = {
    hintText: 'Kies een type solar carport',
    items: [
      { name: 'Stevige ondergrond', value: 0 },
      { name: 'Zwakke ondergrond', value: 1 },
    ]
  }
  private funderingLowCosts: number = 0.1 //Euro per Wp
  private funderingHighCosts: number = 0.2 //Euro per Wp
  public funderingOption: number = 0;
  customFunderingCosts: number;

  solarCarportFixedCostsSmall: number = 5000;
  solarCarportFixedCostsMid: number = 20000;
  solarCarportFixedCostsLarge: number = 95000;
  solarCarportPvCostsSmall: number = 0.35;            // Euro per Wp
  solarCarportPvCostsMid: number = 0.3;            // Euro per Wp
  solarCarportPvCostsLarge: number = 0.28;            // Euro per Wp
  solarCarportConstructionCostsSmall: number = 0.4;  // Euro per Wp
  solarCarportConstructionCostsMid: number = 0.35;  // Euro per Wp
  solarCarportConstructionCostsLarge: number = 0.3; // Euro per Wp  
  solarCarportExtraHeightCosts: number = 0.15; // Euro per Wp
  solarCarportExtraWoodenCosts: number = 0.4;  //Euro per Wp
  solarCarportPersonalizedExtraCosts: number = 0.05; //Euro per Wp
  solarCarportPersonalizedFixedCosts: number = 5000;
  solarCarportMidSizeThreshold: number = 100;  // From 100kWp we get first scaling advantages
  solarCarportLargeSizeThreshold: number = 1000;  // From 1000kWp we get more scaling advantages

  public chargerOptions = {
    hintText: 'Kies een type laadpunt',
    items: [
      { name: 'Geen laadpunten', value: 0 },
      { name: '22kW - Normaal', value: 1 },
      { name: '22kW - Twee laadpunten per paal', value: 2 },
      { name: '50kW - Snellader', value: 3 },
      { name: '150kW - Ultrasnellader', value: 4 },
    ]
  }
  public carportAreaOptions = {
    hintText: 'Hoeveel zonnige oppervlakte overkappen?',
    items: [
      { name: 'Parkeervakken overkappen (60%)', value: 0 },
      { name: 'Aaneengesloten overkapping (95%)', value: 1 },
      { name: 'Eigen keuze maken', value: 2 }
    ]
  }
  public carportAreaType: number = 0;
  public batteryCostOptions = {
    hintText: 'Hoeveel opslag is gewenst?',
    items: [
      { name: 'Geen batterijsysteem', value: 0 },
      { name: 'Klein (75kWh)', value: 1 },
      { name: 'Middel (500kWh)', value: 2 },
      { name: 'Groot (1000kWh)', value: 3 },
      { name: 'Extra groot (2000kWh)', value: 4 },
      { name: 'Eigen keuze maken', value: 5 }
    ]
  }
  public batteryType: number = 0;
  public batterySize: number = 0;
  private yearlyBatteryTradingProfitPerKwh: number = 46.8 // Euro per kWh --> Based on 900 eur/MW /week
  public fractionOfBatteryUsedForTrading: number = 0.5 //50% of the battery capacity is used for trading
  batteryCosts: { threshold: number, fixedCosts: number, variableCosts: number }[] = [
    { threshold: 500, fixedCosts: 15000, variableCosts: 470 }, // Threshold is in kWh, tariff is in euros 
    { threshold: 2000, fixedCosts: 50000, variableCosts: 400 },
    { threshold: 999999999, fixedCosts: 150000, variableCosts: 350 },
  ]

  public chargerCosts: number[] = [0, 1500, 2500, 20000, 60000]
  private extraChargerInstallFraction = 1.25
  public defaultChargerCosts: number[] = [0, 1500, 2500, 20000, 60000]
  public securityOptions = {
    hintText: 'Kies een type beveiliging',
    items: [
      { name: 'Geen beveiliging', value: 0 },
      { name: 'Camerasysteem', value: 1 },
      { name: 'Camerasysteem + hekwerk', value: 2 },
    ]
  }
  public securityType: number = 0;
  public securityCameraFixedCosts: number = 10000;
  public securityCameraVariableCosts: number = 2; // Camera variable Costs in euro /m2 carport area
  public securityFenceFixedCosts: number = 5000;
  public securityFenceVariableCosts: number = 80; //Costs in euro per meter fence
  public customSecurityCosts: number;
  public defaultRentTariff: number = 0;
  public customRentTariff: number;
  public customResearchCosts: number;
  public customMaintenanceCosts: number;
  public customYearlyInsuranceCosts: number;
  public maintenanceCosts: { threshold: number, tariff: number }[] = [
    { threshold: 0, tariff: 15 },     //Treshold is in kWp, tariff in euros/kWp
    { threshold: 500, tariff: 10 },
    { threshold: 1000, tariff: 5 },
  ]
  public researchFixedCosts: number = 2500
  public researchDrillingCosts: number = 625 / 2; // Variable costs are 625 eyuros for doing 2 ground diggings 
  public researchAreaPerDrilling: number = 500; // A ground digging needs to be done every 25 meters, so for a square area approximately one digging per 25*25 = 625m2. We take one drilling per 500m2 to account for rectangular parking areas
  private lightVariableCosts: number[] = [3.5, 1.5] //
  private lightFixedCosts: number[] = [5000, 10000] //Fixed costs for lighting. 
  private lightCarortSizeThreshold: number = 2500 // m2 of solar carport area after which lower variable costs incurr
  public customLightCosts: number;
  public lightOptions = {
    hintText: 'Wil je verlichting?',
    items: [
      { name: 'Nee', value: 0 },
      { name: 'Ja', value: 1 },
    ]
  }
  public lightType: number = 1;

  //  Constants for the revenue part
  subsidyOption: number = 1;
  subsidyDropdown = {
    hintText: 'Maak uw keuze',
    items: [
      { value: 0, name: 'Geen subsidie' },
      { value: 1, name: 'SDE++' },
      { value: 2, name: 'SCE' },
    ]
  };

   SCE_OPTIONS = {
    hintText: 'Kies een aansluitingsgrootte',
    items: [
      {value: 'small', name: 'Laagaansluiting'},
      {value: 'middel', name: 'Middelaansluiting'},
    ]
  }
  public sceConnection: string = 'small'

  SCE_SUBSIDES = {
    'small': 0.124, 
    'middel': 0.096
  }

  SDE_OPTIONS = {
    hintText: "U kunt een van de volgende fases selecteren",
    items: [
      {value: 1, name: 'Fase 1'},
      {value: 2, name: 'Fase 2'},
      {value: 3, name: 'Fase 3'},
      {value: 4, name: 'Fase 4'},
    ]
  }

  SDE_SUBSIDIES = {
    1: {'small':0.0730, 'big': 0.0588},
    2: {'small':0.0724, 'big': 0.0631},
    3: {'small':0.0724, 'big': 0.0655},
    4: {'small':0.0724, 'big': 0.0655}
  }
  public sdeSubsidyPhase: number = 1

  public defaultFixedOtherSubsidy: number = 0;
  public customFixedOtherSubsidy: number;
  public defaultYearlyOtherSubsidy: number = 0;
  public customYearlyOtherSubsidy: number;

  public Co2SavingsPerKwh: number = 0.187;
  public salderenTariff: number = 0.225;
  public ownUseKwhDefaultTariff: number = 20 // Euro cents per kWh
  public ownUseKwhCustomTariff: number;  // Euro cents per kWh
  public salderenDefaultKwhUsage: number = 0;
  public salderenCustomKwhUsage: number;
  public salderenThreshold: number = 15; // Threshold in kWp
  private salderenTotalYearsLeft: number = 6.76; // This is the total number of full salderen years left (i.e. 2021,2022 is 100% 2023 is 91% 2024 is 82% etc...) 

  public midVoltageThreshold: number = 160; // Threshold in kWp
  public gridSalesDefaultTariff: number = 5.4;

  public SDE_THRESHOLD_SMALL: number = 15; //SDE threshold in kWp
  public SDE_THRESHOLD_BIG: number = 1000; //SDE threshold in kWp
  public SDE_UNDER_THRESHOLD: number = 7.3; // Tariff in cts/kWh
  public SDE_OVER_THRESHOLD: number = 5.88;// Tariff in cts/kWh

  public SCE_LOWER_THRESHOLD: number = 15 // SCE threshold in kWp
  public SCE_UPPER_THRESHOLD_SMALL: number = 100 // SCE threshold in kWp
  public SCE_UPPER_THRESHOLD_BIG: number = 500 // SCE threshold in kWp
  public SCE_THRESHOLD_TARIFF_SMALL: number = 12.4; // Tariff in cts/kWh
  public SCE_THRESHOLD_TARIFF_BIG: number = 9.6; // Tariff in cts/kWh

  public correctionPriceGridConnection: number = 3.7 // ct/ kWh
  public correctionPriceNoGridConnection: number = 7.2 // ct/ kWh
  public sceSubsidieDuration: number = 15 // years
  public FULL_LOAD_HOURS: number = 900 // max full load hours per year in subsidy
  public gridSalesCustomTariff: number;
  public extraParkingDefaultTariff: number = 0;
  public extraParkingCustomTariff: number;
  public parkingTime: number = 240;
  public totalParkingMinutesPerDay: number = 480;
  public parkingCoverage: number = 0.1;
  public parkedElectricVehicleFraction: number = 0.1;
  public areaPerParkingSpot: number = 13;
  public defaultNumChargers: number = 4;
  public customNumChargers: number;
  public chargerType: number = 1;
  public chargerSpeed: number[] = [0, 22, 22, 50, 150]
  chargingTariff: number = 0.25; // Average charging tariff in euros/kWh
  chargingOwnUseFraction: number = 0.25;
  averageChargingRange: number = 20 // Average charging range in kWh
  operationalDaysPerYear: number = 365 // How many times a year can we charge cars?
  public salderenOptions = {
    hintText: 'Maak uw keuze',
    items: [
      { name: 'Geen mogelijkheid tot salderen', value: 0 },
      { name: 'Salderen, verbruik gemiddeld huishouden', value: 3000 }, //Value is the average yearly usage in kWh 
      { name: 'Salderen, middelgrote energieafnemer ', value: 10000 },
      { name: 'Salderen, grote energieafnemer', value: 20000 }
    ]
  }
  public parkingTimeOptions = {
    hintText: 'Hoe lang duurt het parkeren?',
    items: [
      { name: 'Kort (30 min)', value: 30 },
      { name: 'Middellang (90 min)', value: 90 },
      { name: 'Lang (4 uur)', value: 240 },
      { name: 'Hele dag (8 uur)', value: 480 }
    ]
  }
  public parkingCoverageOptions = {
    hintText: 'Hoe vol staat het parkeerterrein?',
    items: [
      { name: 'Gemiddeld helemaal leeg', value: 0 },
      { name: 'Gemiddeld 10% vol', value: 0.10 },
      { name: 'Gemiddeld 25% vol', value: 0.25 },
      { name: 'Gemiddeld 50% vol', value: 0.5 },
      { name: 'Gemiddeld 75% vol', value: 0.75 },
      { name: 'Gemiddeld helemaal vol', value: 1.0 }
    ]
  }

  public totalSuitabilityPopovers: { title: string, content: string }[] = [
    {
      title: "Slecht",
      content: "Dit parkeerterrein voldoet ‘slecht’ aan de randvoorwaarden. Dat betekent dat twee of meer van de randvoorwaarden voor deze locatie de score ‘slecht’ hebben. Bekijk hieronder welke randvoorwaarden dat zijn. Let op: de randvoorwaarden score is niet het enige dat bepaalt hoe geschikt het parkeerterrein is voor een solar carport! De variabelen bij ‘Investering’ en ‘Opbrengst’ hebben ook een groot effect op de geschiktheid. Schrijf een parkeerterrein met een rode vlag dus niet zomaar af."
    },
    {
      title: "Middelmatig",
      content: "Dit parkeerterrein voldoet ‘middelmatig’ aan de randvoorwaarden. Dat betekent dat één of meerdere van de randvoorwaarden voor deze locatie de score ‘middelmatig’ hebben. Bekijk hieronder welke randvoorwaarden dat zijn. Let op: de randvoorwaarden score is niet het enige dat bepaalt hoe geschikt het parkeerterrein is voor een solar carport! De variabelen bij ‘Investering’ en ‘Opbrengst’ hebben ook een groot effect op de geschiktheid. Schrijf een parkeerterrein met een rode vlag dus niet zomaar af."
    },
    {
      title: "Goed",
      content: "Dit parkeerterrein voldoet ‘goed’ aan de randvoorwaarden. Dat betekent dat (op ten meeste 1 na) alle randvoorwaarden voor deze locatie de score ‘goed’ hebben. Bekijk hieronder de details. Let op: de randvoorwaarden score is niet het enige dat bepaalt hoe geschikt het parkeerterrein is voor een solar carport! De variabelen bij ‘Investering’ en ‘Opbrengst’ hebben ook een groot effect op de geschiktheid. Schrijf een parkeerterrein met een rode vlag dus niet zomaar af."
    },
  ]

  private rotterdamLegesCosts: { threshold: number, tariff: number }[] = [
    { threshold: 0, tariff: 0.0163 },
    { threshold: 35900, tariff: 0.0187 },
    { threshold: 530000, tariff: 0.0203 },
    { threshold: 1100000, tariff: 0.0208 },
    { threshold: 10700000, tariff: 0.0224 },
    { threshold: 26700000, tariff: 0.0086 },
  ]
  private rotterdamLegesMax: number = 1051675.10

  public ownUseFraction: number = 0;
  public ownUsePercentageOptions = {
    hintText: 'Hoeveel procent van de opgewekte energie wordt zelf gebruikt?',
    items: [
      { name: '0%', value: 0 },
      { name: '10%', value: 0.10 },
      { name: '25%', value: 0.25 },
      { name: '50%', value: 0.5 },
      { name: '75%', value: 0.75 },
      { name: '100%', value: 1.0 }

    ]

  }


  constructor(private cartoService: CartoService) { }

  public roundTens(inputNumber: number, digits: number): number {
    return Math.round(inputNumber / (10 ** digits)) * 10 ** digits
  }

  public getCarportArea(): number {
    return this.buildableAreaPercentage * this.getSunnyArea()
  }

  public editCarportArea(event) {
    if (event.value == 0) {
      this.buildableAreaPercentage = 0.6
    }
    else if (event.value == 1) {
      this.buildableAreaPercentage = 0.95
    } else {
      return
    }
  }

  public getSolarParkingPower(divideByTens: number): number {
    const solarPanelPowerPerm2 = this.cartoService.solarPanelKwpNew / this.cartoService.solarPanelm2New
    return (this.getCarportArea() * solarPanelPowerPerm2 / (10 ** divideByTens))
  }

  public getConnectionInfo() {
    if (this.getSolarParkingPower(3) <= 160) {
      return 'Uw aansluiting is kleiner dan 160 kWp. U kunt kiezen tussen een laag- of middenspanningsaansluiting. Dit heeft mogelijk gevolgen voor de subsidiehoogte.'
    } else if (this.getSolarParkingPower(3) > 160) {
      return 'Uw aansluiting is groter dan 160 kWp. U heeft een middenspanningsaansluiting nodig. Dit heeft mogelijk gevolgen voor de subsidiehoogte.'
    }
  }

  public getPaybackWarning() {
    if (this.getSolarParkingPower(3) < 15 || this.getSolarParkingPower(3) > 500) {
      return "Let op, SCE is alleen geldig voor een geinstalleerd vermogen tussen de 15 en 500 kWp."
    }
    if (this.getPaybackTime() >15) {
      return 'Let op, de SCE regeling is allen maar geldig voor 15 jaar, de verwachte terugverdientijd is hoger.'
    } else {
      return 
    }
  }
  
  public getGridConnectionName() {
    const power = this.getSolarParkingPower(3)
    if (power < this.stage1GridConnections[0].value) {
      return this.stage1GridConnections[0].name
    } else if (power < this.stage1GridConnections[1].value) {
      return this.stage1GridConnections[1].name
    } else if (power < this.stage1GridConnections[2].value) {
      return this.stage1GridConnections[2].name
    } else if (power < this.stage1GridConnections[3].value) {
      return this.stage1GridConnections[3].name
    } else if (power < this.stage1GridConnections[4].value) {
      return this.stage1GridConnections[4].name
    } else if (power < this.stage1GridConnections[5].value) {
      return this.stage1GridConnections[5].name
    } else {
      return this.stage1GridConnections[6].name
    }
  }

  getGridDistance() {
    const carportKwp = this.getSolarParkingPower(3); // Get power in kWp
    if (carportKwp < this.midVoltageThreshold) {
      return this.getLowVoltageGridDistance()
    } else {
      return this.getMidVoltageGridDistance()
    }
  }

  public getMidVoltageGridDistance() {
    if (this.customMidVoltageGridDistance > -1) {
      return this.customMidVoltageGridDistance
    } else {
      return Math.round(this.cartoService.parkingData.griddist_m)
    }
  }

  public getLowVoltageGridDistance() {
    if (this.customLowVoltageGridDistance) {
      return this.customLowVoltageGridDistance
    } else {
      return this.defaultLowVoltageGridDistance
    }
  }

  public isProtectedCityscape(): boolean {
    return this.cartoService.parkingData.protected === this.cartoService.protectedFilter
  }

  public getRentTariff(): number {
    if (this.customRentTariff > -1) {
      return this.customRentTariff
    } else {
      return this.defaultRentTariff
    }
  }

  getYearlyRentCosts(): number {
    return this.getRentTariff() * this.cartoService.parkingData.area
  }

  getSolarCarportCosts(): number {
    const power = this.getSolarParkingPower(0); // Get power in Wp
    let solarCarportCosts = 0
    if (power < this.solarCarportMidSizeThreshold * 1000) {
      solarCarportCosts += this.solarCarportFixedCostsSmall + power * (this.solarCarportPvCostsSmall + this.solarCarportConstructionCostsSmall)
    } else if (power < this.solarCarportLargeSizeThreshold * 1000) {
      solarCarportCosts += this.solarCarportFixedCostsMid + power * (this.solarCarportPvCostsMid + this.solarCarportConstructionCostsMid)
    } else {
      solarCarportCosts += this.solarCarportFixedCostsLarge + power * (this.solarCarportPvCostsLarge + this.solarCarportConstructionCostsLarge)
    }

    if (this.solarCarportType === 0) {
      // Standard carport
      return solarCarportCosts
    } else if (this.solarCarportType === 1) {
      // Personalized carport
      const extraPersonalizedCosts = this.solarCarportPersonalizedFixedCosts + this.solarCarportPersonalizedExtraCosts * power
      return solarCarportCosts + extraPersonalizedCosts
    } else if (this.solarCarportType === 2) {
      // Extra heigh carport
      const extraHeightCosts = this.solarCarportExtraHeightCosts * power
      return solarCarportCosts + extraHeightCosts
    } else if (this.solarCarportType === 3) {
      // Wooden carport
      const extraWoodenCosts = this.solarCarportExtraWoodenCosts * power
      return solarCarportCosts + extraWoodenCosts
    } else {
      return undefined
    }
  }

  getFunderingCosts(): number {
    const power = this.getSolarParkingPower(0); // Get power in Wp
    if (this.customFunderingCosts > -1) {
      // Custom costs
      return this.customFunderingCosts
    } else if (this.funderingOption === 0) {
      // Strong ground, little fundering needed
      return this.funderingLowCosts * power
    } else if (this.funderingOption === 1) {
      // Weak ground, more expensive fundering needed
      return this.funderingHighCosts * power
    } else {
      // This case shouldn't happen. But added it just to be sure
      return 0
    }
  }

  getResearchCosts(): number {
    if (this.customResearchCosts) {
      return this.customResearchCosts
    } else {
      // There is a minimum of two ground drillings for a carport (drillings are done in pairs)
      const numberOfDrillings = Math.max(Math.ceil(this.getCarportArea() / this.researchAreaPerDrilling), 2)
      const totalVariableCosts = this.researchDrillingCosts * numberOfDrillings
      return this.researchFixedCosts + totalVariableCosts
    }
  }

  getFixedGridCosts(): number {
    if (this.yesNoGridConnection) {
      // Grid connection already exists. No costs!
      return 0
    }

    //  This is specific for grid provider Liander, have to make general for all grid provicers later on (but it's roughly the same)
    const carportKwp = this.getSolarParkingPower(3); // Get power in kWp
    let gridCostsFixed = 0;
    let gridCostsExtraLength = 0;

    for (const [index, gridLevel] of this.gridCostsFixed.entries()) {
      if (carportKwp < gridLevel.threshold) {
        gridCostsFixed = this.gridCostsFixed[index].tariff
        gridCostsExtraLength = this.gridCostsExtraLength[index].tariff * this.getGridDistance()
        break
      }
    }
    return gridCostsFixed + gridCostsExtraLength
  }

  getLowVoltageFixedGridCosts(): number {
    // This part needs some improvement! Now only using 1 tariff for the low voltage grid,t there are 2 actually
    const gridCostsFixed = this.lowVoltageGridCostsFixed
    const gridCostsExtraLength = this.lowVoltageGridExtraLengthTariff * this.getLowVoltageGridDistance()

    return gridCostsFixed + gridCostsExtraLength
  }

  getYearlyGridCosts(): number {
    //  This is specific for grid provider Liander, have to make general for all grid provicers later on (but it's roughly the same)
    const carportKwp = this.getSolarParkingPower(3); // Get power in kWp
    let yearlyGridCostsVariable = 0;

    // Note these are just the so called Aansluitdienst. We also need to include the TRANSPORTTARIEF!
    for (const [index, gridLevel] of this.gridCostsFixed.entries()) {
      if (carportKwp < gridLevel.threshold) {
        yearlyGridCostsVariable = this.monthlyGridCostsVariable[index].tariff * 12
        break
      }
    }

    return yearlyGridCostsVariable
  }


  getYearlyMaintenanceCosts(): number {
    if (this.customMaintenanceCosts) {
      return this.customMaintenanceCosts
    } else {
      let power = this.getSolarParkingPower(3); // Get power in kWp

      return Math.min(power, this.maintenanceCosts[1].threshold) * this.maintenanceCosts[0].tariff +
        Math.max((Math.min(power, this.maintenanceCosts[2].threshold) - this.maintenanceCosts[1].threshold), 0) * this.maintenanceCosts[1].tariff +
        Math.max(power - this.maintenanceCosts[2].threshold, 0) * this.maintenanceCosts[2].tariff
    }
  }

  public getNumChargers(): number {
    if (this.customNumChargers > -1) {
      return this.customNumChargers
    } else {
      return this.defaultNumChargers
    }
  }

  getChargerCosts(): number {
    return this.chargerCosts[this.chargerType] * this.getNumChargers() * this.extraChargerInstallFraction
  }

  public getYearlyInsuranceCosts(): number {
    if (this.customYearlyInsuranceCosts) {
      return this.customYearlyInsuranceCosts
    } else {
      let totalCosts = this.getSolarCarportCosts() + this.getChargerCosts()
      return totalCosts * this.insuranceFractionYearly
    }
  }

  public getSecurityCosts(): number {
    const carportArea = this.getCarportArea()
    if (this.securityType === 0) {
      // No security option chosen
      return 0
    } else if (this.customSecurityCosts > -1) {
      // Custom security costs
      return this.customSecurityCosts
    } else if (this.securityType === 1) {
      // Camera system
      return this.securityCameraFixedCosts + this.securityCameraVariableCosts * carportArea
      // return this.securityCosts[this.securityType] * carportArea
    } else if (this.securityType === 2) {
      // Camera system + fencing
      const perimeter = 4 * Math.sqrt(carportArea)
      const cameraCosts = this.securityCameraFixedCosts + this.securityCameraVariableCosts * carportArea
      const fencingCosts = this.securityFenceFixedCosts + perimeter * this.securityFenceVariableCosts
      return cameraCosts + fencingCosts
    } else {
      // This case shouldn't happen, but just to be sure to catch exceptions
      return 0
    }
  }


  public getBatteryCosts(): number {
    // This is the case when no battery system is selected, or the user changes the input to 0 or lower
    if (this.batterySize <= 0) {
      return 0
    }

    // This is the case when a battery system is selected
    let batteryCosts = 0;
    for (const [index, batteryCostObject] of this.batteryCosts.entries()) {
      if (this.batterySize < batteryCostObject.threshold) {
        batteryCosts = batteryCostObject.fixedCosts + batteryCostObject.variableCosts * this.batterySize
        break
      }
    }

    return batteryCosts
  }

  public editBatteryCost(event) {
    if (event.value == 0) {
      this.batterySize = 0
    }
    else if (event.value == 1) {
      this.batterySize = 50
    }
    else if (event.value == 2) {
      this.batterySize = 500
    }
    else if (event.value == 3) {
      this.batterySize = 1000
    }
    else if (event.value == 4) {
      this.batterySize = 2000
    } 
    else {
      return
    }
  }

  public getYearlyBatteryRevenue(): number {
    if (this.batterySize <= 0) {
      return 0
    }
    let tradingKwh = this.batterySize * this.fractionOfBatteryUsedForTrading
    let yearlyBatteryProfit = this.yearlyBatteryTradingProfitPerKwh * tradingKwh
    return Math.max( yearlyBatteryProfit, 0)
    
  }

  public getLightCosts(): number {
    const carportArea = this.getCarportArea()
    if (this.lightType === 0) {
      // No lighting option chosen
      return 0
    } else if (this.customLightCosts > -1) {
      // Custom light costs
      return this.customLightCosts
    } else if (carportArea < this.lightCarortSizeThreshold) {
      // Small carport case
      return this.lightFixedCosts[0] + carportArea * this.lightVariableCosts[0]
    } else {
      // Large carport case
      return this.lightFixedCosts[1] + carportArea * this.lightVariableCosts[1]
    }
  }

  getYearlySubsidyRevenue(): number {
    if (this.subsidyOption === 1) {
      return this.getSdeSubsidyRevenue()
    } else if (this.subsidyOption === 2) {
      return this.getSCESubsidyRevenue()
    } else {
      return 0
    }
  }

  getSdeSubsidyRevenue() {
    const carportKwp = this.getSolarParkingPower(3); // Get power in kWp
    let gridEnergyTariff = this.gridSalesDefaultTariff / 100; // Energy tariff in euros /kWh
    let size = carportKwp < this.SDE_THRESHOLD_BIG? 'small' : 'big'
    let sdePhase = this.SDE_SUBSIDIES[this.sdeSubsidyPhase]
    let sdeSubsidyPerPhasePerKwp = sdePhase[size]
    if (carportKwp > this.SDE_THRESHOLD_SMALL && carportKwp < this.SDE_THRESHOLD_BIG) {
      return Math.max(sdeSubsidyPerPhasePerKwp  - gridEnergyTariff, 0) * this.getYearlyEnergy(3)
    } else if (carportKwp > this.SDE_THRESHOLD_BIG) {
      return Math.max(sdeSubsidyPerPhasePerKwp - gridEnergyTariff, 0) * this.getYearlyEnergy(3)
    } else {
      return 0 // If carpport is too small, it can't get SDE subsidy
    }
  }

  getSCESubsidyRevenue(): number {
    const carportKwp = this.getSolarParkingPower(3); 
    var maxSubsidyPerYear = 0
    var netSupplySCE = 0
    var sceReturnPerYear = 0

    if(carportKwp >= this.SCE_LOWER_THRESHOLD && carportKwp <=  this.SCE_UPPER_THRESHOLD_SMALL && this.sceConnection == 'small') {
      netSupplySCE = (this.SCE_THRESHOLD_TARIFF_SMALL - this.correctionPriceGridConnection)/100 // ct/kWh
    } else if(carportKwp >= this.SCE_LOWER_THRESHOLD && carportKwp <=  this.SCE_UPPER_THRESHOLD_SMALL && this.sceConnection == 'big') {
      let netSupplySCEConnection = (this.SCE_THRESHOLD_TARIFF_BIG - this.correctionPriceGridConnection)/100 // ct/kWh
      let netSupplySCENoConnection = (this.SCE_THRESHOLD_TARIFF_BIG - this.correctionPriceNoGridConnection)/100 // ct/kWh
      netSupplySCE = (1 - this.ownUseFraction) * netSupplySCEConnection + this.ownUseFraction * netSupplySCENoConnection
    } else if(carportKwp >= this.SCE_UPPER_THRESHOLD_SMALL && carportKwp <=  this.SCE_UPPER_THRESHOLD_BIG) {
      this.sceConnection = 'middel'
      let netSupplySCEConnection = (this.SCE_THRESHOLD_TARIFF_BIG - this.correctionPriceGridConnection)/100 // ct/kWh
      let netSupplySCENoConnection = (this.SCE_THRESHOLD_TARIFF_BIG - this.correctionPriceNoGridConnection)/100 // ct/kWh
      netSupplySCE = (1 - this.ownUseFraction) * netSupplySCEConnection + this.ownUseFraction * netSupplySCENoConnection
    } else {
      netSupplySCE = 0
    }
    maxSubsidyPerYear = carportKwp * this.FULL_LOAD_HOURS 
    sceReturnPerYear = maxSubsidyPerYear * netSupplySCE
    return sceReturnPerYear
  }

  getYearlyEnergy(divideByTens: number): number {
    return this.FULL_LOAD_HOURS * this.getSolarParkingPower(divideByTens)
  }


  salderenPossible(): boolean {
    return this.getSolarParkingPower(3) < this.salderenThreshold
  }

  getYearlySalderenRevenue(): number {
    // We cannot use the salderen option
    if (!this.salderenPossible()) {
      return 0
    }

    // This is based on the subsidy which is being decreased over the years. We sum the percentage over years 2021 till 2031
    return this.getSalderenKwhUsage() * this.salderenTariff * (this.salderenTotalYearsLeft / this.carportOperationalYears);
  }

  getSalderenKwhUsage() {
    // We can use the salderen option
    if (this.salderenCustomKwhUsage) {
      return Math.min(this.salderenCustomKwhUsage, this.getYearlyEnergy(3))
    } else {
      return Math.min(this.salderenDefaultKwhUsage, this.getYearlyEnergy(3))
    }
  }

  getGridSalesTariff() {
    return this.gridSalesCustomTariff ? this.gridSalesCustomTariff : this.gridSalesDefaultTariff
  }

  getYearlyGridSalesRevenue(): number {
    const chargingYearlyKwhGeneratedSelf = this.chargingOwnUseFraction * this.getYearlyChargingKwh()
    let gridSalesKwh = this.getYearlyEnergy(3) * (1 - this.ownUseFraction) - chargingYearlyKwhGeneratedSelf
    let gridSalesTariff = this.gridSalesDefaultTariff / 100  //Tariff in euros
    this.gridSalesCustomTariff ? gridSalesTariff = (this.gridSalesCustomTariff / 100) : null

    if (this.salderenPossible) {
      return Math.max(gridSalesKwh - this.getSalderenKwhUsage(), 0) * gridSalesTariff
    } else {
      return gridSalesKwh * gridSalesTariff
    }
  }

  numChargingPoints(): number {
    if (this.chargerType == 2) {
      return this.getNumChargers() * 2
    } else {
      return this.getNumChargers()
    }
  }

  numParkedElectricCars(): number {
    return this.getCarportArea() * this.parkingCoverage * this.parkedElectricVehicleFraction / this.areaPerParkingSpot
  }

  getYearlyChargingKwh(): number {
    let utilizedChargers = Math.min(this.numChargingPoints(), this.numParkedElectricCars())
    let carsPerSpotPerDay = (this.totalParkingMinutesPerDay / this.parkingTime)
    let chargingKwhPerCar = Math.min(this.chargerSpeed[this.chargerType] * this.parkingTime / 60, this.averageChargingRange)
    return utilizedChargers * carsPerSpotPerDay * chargingKwhPerCar * this.operationalDaysPerYear
  }

  getYearlyChargingRevenue(): number {
    if (this.chargerType === 0) {
      return 0
    }
    let averageChargingProfit = this.chargingTariff * this.chargingOwnUseFraction + (this.chargingTariff - this.getOwnUseKwhTariff()) * (1 - this.chargingOwnUseFraction)
    return averageChargingProfit * this.getYearlyChargingKwh()
  }

  getYearlyExtraParkingRevenue(): number {
    let parkedCars = this.getCarportArea() * this.parkingCoverage / this.areaPerParkingSpot
    return (this.getExtraParkingTariff() / 100) * parkedCars * (this.totalParkingMinutesPerDay / 60) * this.operationalDaysPerYear
  }

  getExtraParkingTariff(): number {
    if (this.extraParkingCustomTariff > -1) {
      return this.extraParkingCustomTariff
    } else {
      return this.extraParkingDefaultTariff
    }
  }

  getCo2Savings(): number {
    return this.getYearlyEnergy(3) * this.Co2SavingsPerKwh
  }

  public getSunnyArea() {
    if (this.customSunnyArea > -1) {
      return this.customSunnyArea
    } else {
      return this.cartoService.parkingData.sunny_area
    }
  }

  public getTotalSuitability(): number {
    const sunnyAreaFraction = this.getSunnyArea() / this.cartoService.parkingData.area
    const protectedCityscape = this.isProtectedCityscape()
    const midVoltageGridDistance = this.getMidVoltageGridDistance()
    const totalArea = this.cartoService.parkingData.area
    const carportArea = this.getCarportArea()
    const carportPower = this.getSolarParkingPower(3)

    const badConditionsArray = [
      totalArea < 500,
      sunnyAreaFraction < 0.4,
      carportArea < 350,
      carportPower < 75,
      protectedCityscape,
      midVoltageGridDistance > 200
    ]

    const goodConditionsArray = [
      totalArea > 2000,
      sunnyAreaFraction > 0.7,
      carportArea > 1400,
      carportPower > 300,
      !protectedCityscape,
      midVoltageGridDistance < 50
    ]

    if (badConditionsArray.filter(Boolean).length > 1) {
      return 1
    } else if (goodConditionsArray.filter(Boolean).length > 4) {
      return 3
    } else {
      return 2
    }
  }

  public getLegesCosts() {
    const solarCarportCosts: number = this.getSolarCarportCosts();
    if (this.cartoService.selectedMunicipalityId == 193) {
      return this.getLegesRotterdam(solarCarportCosts)
    }
    else {
      return solarCarportCosts * this.legesFraction
    }
  }

  private getLegesRotterdam(solarCarportCosts: number) {
    const totalLegesFromBrackets: number = this.getCostsFromBrackets(solarCarportCosts, this.rotterdamLegesCosts)
    return Math.min(totalLegesFromBrackets, this.rotterdamLegesMax)
  }

  // Function to get costs when they need to be computed from brackets with variable thresholds and tariffs 
  private getCostsFromBrackets(numberToBracket: number, thresholdsAndTariffs: { threshold: number, tariff: number }[]): number {
    let costsInBracket: number[] = [];
    const numBrackets: number = thresholdsAndTariffs.length

    for (let i = 0; i < numBrackets; i++) {
      // For the last bracket the upper boundary is not set
      if (i === (numBrackets - 1)) {
        costsInBracket.push(Math.max(numberToBracket - thresholdsAndTariffs[i].threshold, 0) * thresholdsAndTariffs[i].tariff)
      } else {
        costsInBracket.push(Math.max((Math.min(numberToBracket, thresholdsAndTariffs[i + 1].threshold) - thresholdsAndTariffs[i].threshold), 0) * thresholdsAndTariffs[i].tariff)
      }
    }

    // Return the sum of the costs in each bracket
    return costsInBracket.reduce((a, b) => a + b, 0)
  }

  public getOwnUseRevenue() {
    return (this.getYearlyEnergy(3) * this.ownUseFraction) * (this.getOwnUseKwhTariff())
  }

  // returns the own usage kwh tariff in euros
  private getOwnUseKwhTariff(): number {
    return this.ownUseKwhCustomTariff ? (this.ownUseKwhCustomTariff / 100) : (this.ownUseKwhDefaultTariff / 100)
  }


  public getFixedOtherSubsidy(): number {
    if (this.customFixedOtherSubsidy) {
      return this.customFixedOtherSubsidy
    } else {
      return this.defaultFixedOtherSubsidy
    }
  }

  public getYearlyOtherSubsidy(): number {
    if (this.customYearlyOtherSubsidy) {
      return this.customYearlyOtherSubsidy
    } else {
      return this.defaultYearlyOtherSubsidy
    }
  }

  public getYearlyCosts(): number {
    return this.getYearlyMaintenanceCosts() + this.getYearlyInsuranceCosts() + this.getYearlyRentCosts() + this.getYearlyGridCosts()
  }

  public getFixedCosts(): number {
    return this.getSolarCarportCosts() + this.getFunderingCosts() + this.getFixedGridCosts() + this.getLegesCosts() + this.getResearchCosts() + this.getChargerCosts() + this.getLightCosts() + this.getSecurityCosts() + this.getBatteryCosts();
  }

  public getFixedRevenue(): number {
    return this.getFixedOtherSubsidy()
  }

  public getYearlyRevenue(): number {
    return this.getYearlySubsidyRevenue() + this.getYearlyOtherSubsidy() + this.getYearlyGridSalesRevenue() + this.getOwnUseRevenue() + this.getYearlySalderenRevenue() + this.getYearlyChargingRevenue() + this.getYearlyExtraParkingRevenue() + this.getYearlyBatteryRevenue();
  }

  public getPaybackTime(): number {
    if ((this.getYearlyRevenue() - this.getYearlyCosts()) < 0) {
      return Infinity
    }

    return (this.getFixedCosts() - this.getFixedRevenue()) / (this.getYearlyRevenue() - this.getYearlyCosts())
  }

  public getTotalAreaFlagColor() {
    const totalArea = this.cartoService.parkingData.area
    if (totalArea < 500) {
      return 1
    } else if (totalArea > 2000) {
      return 3
    } else {
      return 2
    }
  }

  public getSunnyFlagColor() {
    let sunnyAreaFraction = this.getSunnyArea() / this.cartoService.parkingData.area
    if (sunnyAreaFraction < 0.4) {
      return 1
    } else if (sunnyAreaFraction > 0.7) {
      return 3
    } else {
      return 2
    }
  }

  public getSolarCarportAreaFlagColor() {
    const carportArea = this.getCarportArea()
    if (carportArea < 350) {
      return 1
    } else if (carportArea > 1400) {
      return 3
    } else {
      return 2
    }
  }

  public getSolarPowerFlagColor() {
    const carportPower = this.getSolarParkingPower(3)
    if (carportPower < 75) {
      return 1
    } else if (carportPower > 300) {
      return 3
    } else {
      return 2
    }
  }

  public getCityscapeFlagColor() {
    if (this.isProtectedCityscape()) {
      return 1
    } else {
      return 3
    }
  }

  public getGridDistanceFlagColor() {
    const midVoltageGridDistance = this.getMidVoltageGridDistance()
    if (midVoltageGridDistance > 200) {
      return 1
    } else if (midVoltageGridDistance < 50) {
      return 3
    } else {
      return 2
    }
  }

  initCustomValues(mapConfig: MapConfig) {
    // Load custom business values
    if (mapConfig.businessCase) {
      if (mapConfig.businessCase.businessValues) {
        this.applyBusinessValues(mapConfig.businessCase.businessValues);
      }
    }
  }

  public applyBusinessValues(data) {
    // Note the if statements are used to check if the values are null. If it is null set to undefined, because null causes errors.
    // First page 
    this.customSunnyArea = data["customSunnyArea"] ? data["customSunnyArea"] : undefined;

    if(data["buildableAreaPercentage"] === 0.60){
      this.carportAreaType = 0;
    } else if(data["buildableAreaPercentage"] === 0.95) {
      this.carportAreaType = 1;
    } else {
      this.carportAreaType = 2;
    }
    this.buildableAreaPercentage = data["buildableAreaPercentage"];

    // Afstand netaansluiting
    // data["lowVoltageGridDistance"] = this.lowVoltageGridDistance;
    this.customLowVoltageGridDistance = data["customLowVoltageGridDistance"];
    this.customMidVoltageGridDistance = data["customMidVoltageGridDistance"];
    this.yesNoGridConnection = data["yesNoGridConnection"];

    this.stage1GridDistanceOption = data["stage1GridDistanceOption"] ? data["stage1GridDistanceOption"] : undefined;


    // Second page
    this.customResearchCosts = data["customResearchCosts"] ? data["customResearchCosts"] : undefined;
    this.funderingOption = data["funderingOption"]
    this.solarCarportType = data["solarCarportType"];
    this.chargerType = data["chargerType"];
    this.customNumChargers = data["customNumChargers"] ? data["customNumChargers"] : undefined;
    this.customRentTariff = data["customRentTariff"] ? data["customRentTariff"] : undefined;
    this.securityType = data["securityType"];
    this.customMaintenanceCosts = data["customMaintenanceCosts"] ? data["customMaintenanceCosts"] : undefined;
    this.customYearlyInsuranceCosts = data["customYearlyInsuranceCosts"] ? data["customYearlyInsuranceCosts"] : undefined;
    this.batteryType = data["batteryType"]
    this.batterySize = data["batterySize"]

    // Third page
    this.subsidyOption = data["subsidyOption"];
    this.customYearlyOtherSubsidy = data["customYearlyOtherSubsidy"]
    this.salderenDefaultKwhUsage = data["salderenDefaultKwhUsage"]
    this.salderenCustomKwhUsage = data["salderenCustomKwhUsage"] ? data["salderenCustomKwhUsage"] : undefined;
    this.gridSalesDefaultTariff = data["gridSalesDefaultTariff"];
    this.gridSalesCustomTariff = data["gridSalesCustomTariff"] ? data["gridSalesCustomTariff"] : undefined;
    this.parkingTime = data["parkingTime"];
    this.parkingCoverage = data["parkingCoverage"];
    this.extraParkingDefaultTariff = data["extraParkingDefaultTariff"];
    this.extraParkingCustomTariff = data["extraParkingCustomTariff"] ? data["extraParkingCustomTariff"] : undefined;
    this.customFixedOtherSubsidy = data["customFixedOtherSubsidy"];
    this.ownUseFraction = data["ownUseFraction"]
  }
}
