// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBto08deWQ6eo6H5wcMVcXqCwxLtYGOrXE",
    authDomain: "park-the-sun.firebaseapp.com",
    databaseURL: "https://park-the-sun.firebaseio.com",
    projectId: "park-the-sun",
    storageBucket: "park-the-sun.appspot.com",
    messagingSenderId: "911121106117",
    appId: "1:911121106117:web:8b4923a9bbb49293249cac",
    measurementId: "G-5G8P9QBTVL"
  },
  // TODO: testing
  // carboneToken: "test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiIyMDg0IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6Mjg1NTQ5NDc1OCwiZGF0YSI6eyJpZEFjY291bnQiOjIwODR9fQ.AaaivgcP3XG7K4GXHzg2w39PluWqNkf2l78WPakpVfUqjyAZcufveEPZsCJBBS0GQJRNuC_EDV-UkmX_k0IoypBbAcF0drWMRj7aEuAH2A73y9_7YmRbfj2nBrdKc3LT9yek4yIwljKc5Ce4dXDU4e1zVkvhp5pAAM1OsHYtrDSR2Z1k",
  carboneToken: "eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiIyMDg0IiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjIwODQzODMxMiwiZGF0YSI6eyJpZEFjY291bnQiOjIwODR9fQ.AbsVHWzqygNoxTDK4I7e_Vogm5_pfeQFcHf5zrlm29mBsvmonhF_XP4rpP0cf7MlUIX4MoYqjQeTNatNcPCHqLBIAAIbw0RW8jGangDpobiLOYYxhdG-9EwiJvZq0_NjGLVAdOP5o7zB5y_Z6as5T31APkhhlUqlrDG3KSJYB5SUNG85",
  dataset: "pts_parkings_nl_dev",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
